import NEWS_PAGE_DATA from '@/graphql/news/NewsPageData.graphql'
import NEWS_OPEN_PAGE from '@/graphql/news/OneNews.graphql'

/**
 * Страница новостей департамента
 * @param state
 * @param commit
 * @param apollo
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_NEWS_PAGE_DATA ({ commit }, apollo) {
  const { data } = await apollo.clients.dept.query({
    query: NEWS_PAGE_DATA
  })
  if (data.NewsCategory) {
    commit('news_categories', data.NewsCategory)
  }
  if (data.News) {
    commit('news', data.News)
  }
  if (data.this_department) {
    commit('this_department', data.this_department)
  }
}

/**
 * Страница новости департамента
 * @param commit
 * @param apollo
 * @param variables
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_NEWS_OPEN ({ commit }, { apollo, variables }) {
  const { data } = await apollo.clients.dept.query({
    query: NEWS_OPEN_PAGE,
    variables: variables
  })
  if (data.OneNews) {
    commit('news_open', data.OneNews)
  }
  if (data.this_department) {
    commit('this_department', data.this_department)
  }
}

export { GET_NEWS_PAGE_DATA, GET_NEWS_OPEN }
