<template>
  <div class="sn__container">
    <div v-if="department.title" class="sn__header">
      <div v-if="department.icon" v-html="department.icon" class="sn__icon icon-big">
      </div>
      <div class="sn__title">
        {{ department.title }}
      </div>
    </div>
    <ul class="sn__links">
      <li v-for="(item, index) in navLinks" :key="index">
        <router-link
          :to="{ name: item.route }"
          :class="{ active: isRouteActive(item.route) }"
          class="sn__link"
        >
          {{ item.title }}
        </router-link>
      </li>
    </ul>
    <ul class="sn__links">
      <li v-for="(item, index) in navLinksBottom" :key="index">
        <router-link
          :to="{ name: item.route }"
          :class="{ active: isRouteActive(item.route) }"
          class="sn__link"
        >
          {{ item.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideNavComponent',
  data () {
    return {
      navLinks: [
        {
          title: 'Главная',
          route: 'home'
        },
        {
          title: 'Новости',
          route: 'news'
        },
        {
          title: 'Деятельность',
          route: 'activity'
        },
        {
          title: 'Структура',
          route: 'structure'
        },
        {
          title: 'Документы',
          route: 'documents'
        },
        {
          title: 'Услуги',
          route: 'services'
        },
        {
          title: 'Подведомственные учреждения',
          route: 'subdivisions'
        }
      ],
      navLinksBottom: [
        {
          title: 'Направить официальное обращение',
          route: 'appeal'
        },
        {
          title: 'Контакты',
          route: 'contacts'
        }
      ]
    }
  },
  computed: {
    department () {
      return this.$store.state.this_department
    }
  },
  methods: {
    isRouteActive (route) {
      return !!(
        this.$route.name === route ||
        (this.$route.meta &&
          this.$route.meta.parent &&
          this.$route.meta.parent.filter((p) => p.name === route).length)
      )
    }
  }
}
</script>

<style lang="stylus">
.sn {
  &__container {
    position sticky
    top 32px // 88px - высота хедера + отступ
    height 100vh
    display flex
    flex-direction column
    gap 24px
    padding 32px
    background-color: var(--color_white);
    +below(1400px) {
      padding 32px 24px
    }
    +below(1100px) {
      display none
    }

    > *:not(:first-child) {
      border-top 1px solid var(--color_gray_divider)
    }
  }

  &__header {
    display flex
    align-items flex-start
    gap 32px
    padding-bottom: 20px;
    +below(1500px) {
      gap 24px
    }
    +below(1200px) {
      gap 4px
    }
  }

  &__icon {
    flex 0 0 64px
    +below(1200px) {
      flex-basis 48px

      svg {
        width: 48px
        height: 48px
      }
    }

    img {
      width 100%
      object-fit contain
      object-position center
    }
  }

  &__title {
    font-weight: 500;
    font-size: 1.375em
    line-height: 28px;
    text-align right
    word-break break-word
    +below(1400px) {
      font-size 1.25em
    }
    +below(1200px) {
      font-size 1.125em
      line-height 24px
    }
  }

  &__links {
    padding-top: 24px;
    display flex
    flex-direction column
    gap 24px
  }

  &__link {
    font-weight: 500;
    font-size: 1.125em
    line-height: 24px;
    transition 0.2s

    &.active {
      color var(--color_red_dark)
    }
  }
}
</style>
