<template>
  <i class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21 16.9999V1H3V16.9999C3 19 4 19.9999 6 19.9999L8.5 19.9998C10.5 19.9999 12 23 12 23C12 23 13.5 19.9998 15.5 19.9999H18C20 19.9999 21 19 21 16.9999ZM5 3H19V16.4998C19 17.5 18.5 17.9999 17.5 17.9999L15 17.9998C13.5 17.9999 12 19.9999 12 19.9999C12 19.9999 10.5 17.9998 9 17.9998L6.47715 17.9999C5.5 17.9999 5 17.5 5.00702 16.4998C5.01403 15.4997 5 3 5 3Z"
        fill="white"
      />
      <path
        d="M19.7495 15.2798C20.065 15.4639 20.4999 14.5 20.4999 14.5C20.4999 14.5 20.9403 13.6627 20.7595 13.5598C20.1537 13.2003 19.4638 13.0072 18.7595 12.9998C18.0295 13.0048 17.3132 13.1977 16.6795 13.5598C16.2884 13.7788 15.8477 13.8938 15.3995 13.8938C14.9513 13.8938 14.5105 13.7788 14.1195 13.5598C13.4922 13.2011 12.7821 13.0125 12.0595 13.0125C11.3369 13.0125 10.6268 13.2011 9.99946 13.5598C9.60839 13.7788 9.16767 13.8938 8.71946 13.8938C8.27125 13.8938 7.83054 13.7788 7.43946 13.5598C6.81753 13.196 6.10999 13.0043 5.38946 13.0043C4.66894 13.0043 3.96139 13.196 3.33946 13.5598C3.15864 13.6627 3.49989 15 3.49989 15C3.49989 15 4.03388 15.4639 4.34946 15.2798C4.6687 15.0955 5.03084 14.9985 5.39946 14.9985C5.76809 14.9985 6.13022 15.0955 6.44946 15.2798C7.1422 15.6706 7.9241 15.876 8.71946 15.876C9.51483 15.876 10.2967 15.6706 10.9895 15.2798C11.3114 15.0928 11.6771 14.9943 12.0495 14.9943C12.4218 14.9943 12.7875 15.0928 13.1095 15.2798C13.8022 15.6706 14.5841 15.876 15.3795 15.876C16.1748 15.876 16.9567 15.6706 17.6495 15.2798C17.9687 15.0955 18.3308 14.9985 18.6995 14.9985C19.0681 14.9985 19.4302 15.0955 19.7495 15.2798Z"
        fill="white"
      />
      <path
        d="M7 5.00004L7.00001 6.99985L7.00001 7.99985C7.00001 8.26507 7.10536 8.51942 7.2929 8.70696C7.48044 8.8945 7.73479 8.99985 8.00001 8.99985L7.25 14.9999L9.25 14.9999L10 8.99985L14 8.99986L14.75 14.9999L16.75 15L16 8.99986C16.2652 8.99986 16.5196 8.8945 16.7071 8.70697C16.8946 8.51943 17 8.26508 17 7.99986L17 6.99986L17 5.00005L15 4.99986L15 6.99986L13 6.99986L13 4.99986L11 4.99985L11 6.99985L9.00001 6.99985L9.00001 4.99985L7 5.00004Z"
        fill="white"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
