<template>
  <i class="icon">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.71 12.7101C16.6904 11.9388 17.406 10.881 17.7572 9.68407C18.1085 8.48709 18.0779 7.21039 17.6698 6.03159C17.2617 4.85279 16.4963 3.83052 15.4801 3.10698C14.4639 2.38344 13.2474 1.99463 12 1.99463C10.7525 1.99463 9.53611 2.38344 8.51993 3.10698C7.50374 3.83052 6.73834 4.85279 6.33021 6.03159C5.92208 7.21039 5.89151 8.48709 6.24276 9.68407C6.59401 10.881 7.3096 11.9388 8.29 12.7101C6.61007 13.3832 5.14428 14.4995 4.04889 15.94C2.95349 17.3806 2.26956 19.0914 2.07 20.8901C2.05555 21.0214 2.06711 21.1543 2.10402 21.2812C2.14093 21.408 2.20246 21.5264 2.28511 21.6294C2.45202 21.8376 2.69478 21.971 2.96 22.0001C3.22521 22.0293 3.49116 21.9519 3.69932 21.785C3.90749 21.6181 4.04082 21.3753 4.07 21.1101C4.28958 19.1553 5.22168 17.3499 6.68822 16.0389C8.15475 14.7279 10.0529 14.0032 12.02 14.0032C13.9871 14.0032 15.8852 14.7279 17.3518 16.0389C18.8183 17.3499 19.7504 19.1553 19.97 21.1101C19.9972 21.3558 20.1144 21.5828 20.2991 21.7471C20.4838 21.9115 20.7228 22.0016 20.97 22.0001H21.08C21.3421 21.97 21.5817 21.8374 21.7466 21.6314C21.9114 21.4253 21.9881 21.1625 21.96 20.9001C21.7595 19.0963 21.0719 17.3811 19.9708 15.9383C18.8698 14.4955 17.3969 13.3796 15.71 12.7101ZM12 12.0001C11.2089 12.0001 10.4355 11.7655 9.77772 11.326C9.11992 10.8865 8.60723 10.2618 8.30448 9.53086C8.00173 8.79995 7.92251 7.99569 8.07686 7.21976C8.2312 6.44384 8.61216 5.73111 9.17157 5.1717C9.73098 4.61229 10.4437 4.23132 11.2196 4.07698C11.9956 3.92264 12.7998 4.00186 13.5307 4.30461C14.2616 4.60736 14.8863 5.12005 15.3259 5.77784C15.7654 6.43564 16 7.209 16 8.00012C16 9.06099 15.5786 10.0784 14.8284 10.8286C14.0783 11.5787 13.0609 12.0001 12 12.0001Z"
        fill="white"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: 'UserIcon'
}
</script>
