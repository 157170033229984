<template>
  <div class="lm">
    <div class="lm__header">
      <LogoIcon />
      <span>Регистрация на сайте Главы города Махачкалы</span>
      <button type="button" @click="$emit('close')" class="lm__close" v-if="!loading">
        <CloseIcon />
      </button>
    </div>
    <div class="lm__body">
      <form @submit.prevent="submitForm" class="lm__form">
        <div class="lm__form-item" :class="{ error: !!form.surname.message }">
          <label for="email">Фамилия</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.surname.value"
              id="surname"
              type="text"
              placeholder="Ваша фамилия"
              autofocus
            />
            <span class="lm__form-item-message" v-if="form.surname.message">{{ form.surname.message }}</span>
          </div>
        </div>
        <div class="lm__form-item" :class="{ error: !!form.name.message }">
          <label for="name">Имя</label>
          <div class="lm__form-item-input">
            <input v-model="form.name.value" id="name" type="text" placeholder="Имя" autofocus />
            <span class="lm__form-item-message" v-if="form.name.message">{{ form.name.message }}</span>
          </div>
        </div>
        <div class="lm__form-item" :class="{ error: !!form.patronymic.message }">
          <label for="patronymic">Отчество</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.patronymic.value"
              id="patronymic"
              type="text"
              placeholder="Отчество"
              autofocus
            />
            <span class="lm__form-item-message" v-if="form.patronymic.message">
              {{ form.patronymic.message }}
            </span>
          </div>
        </div>
        <div class="lm__form-item" :class="{ error: !!form.phone.message }">
          <label for="phone">Номер телефона</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.phone.value"
              id="phone"
              type="text"
              v-mask="'+7 (###) ###-##-##'"
              placeholder="Введите номер телефона"
            />
            <span class="lm__form-item-message" v-if="form.phone.message">{{ form.phone.message }}</span>
          </div>
        </div>
        <div class="lm__form-item" :class="{ error: !!form.email.message }">
          <label for="email">E-mail</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.email.value"
              id="email"
              type="email"
              placeholder="Введите E-mail"
              autofocus
            />
            <span class="lm__form-item-message" v-if="form.email.message">{{ form.email.message }}</span>
          </div>
        </div>
        <div class="lm__form-item form__item-password">
          <label for="password">Пароль</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.password.value"
              id="password"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Введите пароль"
            />
            <a @click.prevent="showPassword = !showPassword" class="password__show-btn" href="#">
              <EyeIcon v-if="showPassword" />
              <EyeCloseIcon v-else />
            </a>
            <span class="lm__form-item-message" v-if="form.password.message">
              {{ form.password.message }}
            </span>
          </div>
        </div>
        <div class="lm__form-item form__item-password">
          <label for="password">Подтвердите пароль</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.password_confirmation.value"
              id="password_confirmation"
              :type="showConfirmationPassword ? 'text' : 'password'"
              placeholder="Введите пароль"
            />
            <a
              @click.prevent="showConfirmationPassword = !showConfirmationPassword"
              class="password__show-btn"
              href="#"
            >
              <EyeIcon v-if="showConfirmationPassword" />
              <EyeCloseIcon v-else />
            </a>
            <span class="lm__form-item-message" v-if="form.password_confirmation.message">
              {{ form.password_confirmation.message }}
            </span>
          </div>
        </div>
        <button class="btn-blue btn--small" type="submit" :disabled="loading">
          <LoadingIndicator v-if="loading" />
          <template v-else>Регистрация</template>
        </button>
        <div class="lm__no-account">
          <span>Есть аккаунт?</span>
          <a class="lm__register-link link__blue" href="#" @click.prevent="showLoginModal">Войти</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LogoIcon from 'components/svg/Logo.vue'
import EyeIcon from 'components/svg/EyeIcon.vue'
import EyeCloseIcon from 'components/svg/EyeCloseIcon.vue'
import CloseIcon from 'components/svg/CloseIcon.vue'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import LoginModal from './LoginModal.vue'

export default {
  name: 'RegisterModal',
  data () {
    return {
      showPassword: false,
      showConfirmationPassword: false,
      loading: false,
      form: {
        email: {
          value: null,
          message: null
        },
        phone: {
          value: null,
          message: null
        },
        surname: {
          value: null,
          message: null
        },
        name: {
          value: null,
          message: null
        },
        patronymic: {
          value: null,
          message: null
        },
        password: {
          value: null,
          message: null
        },
        password_confirmation: {
          value: null,
          message: null
        }
      }
    }
  },
  methods: {
    clearMessages () {
      Object.keys(this.form).forEach((field) => {
        this.form[field].message = null
      })
    },
    showLoginModal () {
      this.$store.state._modals = [
        {
          component: LoginModal
        }
      ]
    },
    async submitForm () {
      if (!this.loading) {
        this.clearMessages()
        this.loading = true
        const fd = new FormData()
        Object.keys(this.form).forEach((key) => {
          if (this.form[key].value !== null) fd.append(key, this.form[key].value)
        })
        await this.$store
          .dispatch('POST_REGISTER', fd)
          .then(({ data }) => {
            this.$store.state.user = data.user
            localStorage.setItem('apollo-token', data.user.api_token)
            location.reload()
            this.loading = false
          })
          .catch(({ response }) => {
            Object.keys(response.data.errors).forEach((message) => {
              Object.keys(this.form).forEach((field) => {
                if (field === message) {
                  this.form[field].message = response.data.errors[message][0]
                }
              })
            })
            this.loading = false
          })
      }
    }
  },
  components: {
    LoadingIndicator,
    CloseIcon,
    LogoIcon,
    EyeIcon,
    EyeCloseIcon
  }
}
</script>

<style lang="stylus">
@import "~@/styles/parts/loginModal.styl"
</style>
