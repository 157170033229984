import DOCUMENTS_PAGE from '@/graphql/documents/DocumentsPage.graphql'

/**
 * Страница документов
 * @param state
 * @param commit
 * @param apollo
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_DOCUMENTS_PAGE ({ commit }, apollo) {
  const { data } = await apollo.clients.dept.query({
    query: DOCUMENTS_PAGE
  })
  if (data.JoinDocuments) {
    commit('documents', data.JoinDocuments)
  }
  if (data.category_documents) {
    commit('document_types', data.category_documents)
  }
  if (data.this_department) {
    commit('this_department', data.this_department)
  }
}

export { GET_DOCUMENTS_PAGE }
