import Vue from 'vue'
import VueApollo from 'vue-apollo'
import fetch from 'node-fetch'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createUploadLink } from 'apollo-upload-client'

Vue.use(VueApollo)

export default function createApollo (host) {
  const cache = new InMemoryCache()
  const deptClient = new ApolloClient({
    link: createUploadLink({
      // uri: 'http://localhost/graphql',
      uri: `https://${host}/graphql`,
      // uri: 'https://org.newmkala.ru/graphql',
      fetch: fetch
    }),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
  })
  return new VueApollo({
    clients: {
      dept: deptClient
    },
    defaultClient: deptClient
  })
}
