<template>
  <div class="modal" v-if="$store.state._modals.length">
    <div class="modal__content" v-for="(modal, index) in $store.state._modals" :key="index">
      <div class="modal__background" @click="close(index)" v-if="canCloseModal(modal)"></div>
      <div
        class="modal__body"
        :class="{
          'modal__body--transparent': modal.options && modal.options.no_background,
          'modal__body--stick-to-top': modal.options && modal.options.stick_to_top,
          'modal__body--full': modal.options && modal.options.full,
        }"
      >
        <component :is="modal.component" @close="close(index)" :options="modal.options"></component>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/svg/CloseIcon.vue";

export default {
  name: "ModalComponent",
  components: { CloseIcon },
  methods: {
    canCloseModal(modal) {
      return !modal.options?.noBackClose;
    },
    close(i) {
      return this.$store.state._modals.splice(i, 1);
    },
  },
  watch: {
    "$store.state._modals.length"(val) {
      if (val > 0) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
  },
};
</script>

<style lang="stylus">
.modal
  fixed left top
  width 100%
  height 100%
  z-index 999
  background: var(--color_dark_o5);
  +below(420px) {
    top 56px // высота хедера
  }
  +below(380px) {
    top 48px
  }

  &__close
    background none
    border none
    padding 0
    cursor pointer
    absolute top 32px right 32px
    //transform translate(-100%, 100%)
    display flex
    align-items center
    justify-content center
    z-index 99
    transition 0.2s
    +below(768px) {
      top 16px
      right 16px
    }

    &:hover
      background-color var(--color_white_d10)

    .icon
      svg
        width 48px
        height 48px
        +below(768px) {
          width 32px
          height: 32px
        }
        +below(420px) {
          width 24px
          height: 24px
        }

        path
          fill var(--color_dark)

  &__content
    width 100%
    height 100%
    position relative
    display none
    align-items flex-start
    justify-content center
    padding 64px 0
    overflow-y auto
    +below(420px) {
      padding 0
      max-height calc(100% - 56px)
    }
    +below(380px) {
      max-height calc(100% - 48px)
    }

    &:last-child
      display flex

  &__background
    fixed left top
    width 100%
    height 100%
    z-index 0
    cursor pointer
    +below(420px) {
      top 56px // высота хедера
    }
    +below(380px) {
      top 48px
    }

  &__body
    position relative
    z-index 1
    margin auto var(--margin)
    +below(420px) {
      margin: 0;
    }

    &--transparent
      background transparent
      box-shadow none

    &--stick-to-top
      margin-top 0

    &--full
      +below(920px) {
        width 100%
      }
</style>
