<template>
  <div id="app">
    <vue-progress-bar />
    <HeaderComponent />
    <div class="main__container">
      <div class="container">
        <SideNavComponent />
        <Transition name="page" mode="out-in">
          <RouterView :key="$route.fullPath" />
        </Transition>
      </div>
    </div>
    <FooterComponent />
    <ModalComponent />
    <notifications position="top center"></notifications>
  </div>
</template>

<script>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'
import ModalComponent from './components/ModalComponent.vue'
import SideNavComponent from './components/SideNavComponent.vue'

export default {
  created () {
    this.$Progress.start()
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })
    this.$router.afterEach(() => {
      this.$Progress.finish()
    })
  },
  mounted () {
    this.$Progress.finish()
  },
  watch: {
    '$route' () {
      window.scrollTo(0, 0)
    }
  },
  metaInfo () {
    return this.$seo()
  },
  components: {
    ModalComponent,
    FooterComponent,
    HeaderComponent,
    SideNavComponent
  }
}
</script>

<style lang="stylus">
@import "styles/style.styl"
</style>
