import CATEGORY_SERVICES from '@/graphql/services/CategoryServices.graphql'
import ONE_SERVICE from '@/graphql/services/OneService.graphql'

/**
 * Страница услуг
 * @param state
 * @param commit
 * @param apollo
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_SERVICES_PAGE ({ commit }, apollo) {
  const { data } = await apollo.clients.dept.query({
    query: CATEGORY_SERVICES
  })
  if (data.category_services) {
    commit('category_services', data.category_services)
  }
  if (data.popular_services) {
    commit('popular_services', data.popular_services)
  }
  if (data.service_banners_paginate) {
    commit('banners', data.service_banners_paginate)
  }
  if (data.this_department) {
    commit('this_department', data.this_department)
  }
}

/**
 * Страница услуги
 * @param commit
 * @param apollo
 * @param variables
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_SERVICE ({ commit }, { apollo, variables }) {
  const { data } = await apollo.clients.dept.query({
    query: ONE_SERVICE,
    variables: variables
  })
  if (data.service) {
    commit('service', data.service)
  }
  if (data.this_department) {
    commit('this_department', data.this_department)
  }
}

export { GET_SERVICES_PAGE, GET_SERVICE }
