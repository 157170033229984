import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions/index'
import mutations from './mutations'

Vue.use(Vuex)
export default function createStore (host) {
  return new Vuex.Store({
    actions: actions,
    mutations: mutations,
    state () {
      return {
        api: host ? `https://${host}` : process.env.API || 'http://localhost:8000',
        main_api: 'https://mkala.ru',
        _modals: [],
        this_department: {},
        banners: {},
        home_page: {},
        news: {},
        news_categories: [],
        news_open: {},
        services_page: {},
        service: {},
        feedback_page: {},
        documents: {},
        document_types: [],
        subdivisions: {},
        category_services: [],
        popular_services: [],
        structure_personals: [],
        minister: {},
        user: null,
        type: {
          CODE_MESSAGES: 1, // Сообщения
          CODE_MAYOR_NEWS: 2, // Новости мэра
          CODE_CITY_NEWS: 3, // Новости города
          CODE_REGION_NEWS: 4, // Новости региона
          CODE_REPORT_NEWS: 5, // Отчеты
          CODE_PROJECTS_NEWS: 6, // Проекты
          CODE_ACTIVITY: 7, // Деятельность
          CODE_DEPARTMENTS: 1, // Управление
          CODE_INSTITUTIONS: 2, // Учреждение и организации
          CODE_MAYOR: 1, // Глава города
          CODE_DEPUTY_HEADS: 2, // Заместители главы города
          CODE_ASSISTANT_HEADS: 3, // Помощник главы города
          CODE_HINTERLAND_HEADS: 4, // Главы внутригородских районов
          CODE_INDIVIDUAL: 1, // Тип Физическое лицо
          CODE_ENTITY: 2, // Тип Юридическое лицо
          CODE_PD_POSITIVE: 1, // голос "за"
          CODE_PD_NEGATIVE: 2, // голос "против"
          CODE_PD_AVOID: 3 // голос "воздержался"
        },
        status: {
          CODE_ACTIVE: 1,
          CODE_NOACTIVE: 2,
          CODE_NOT_PUBLISHED: 3
        }
      }
    }
  })
}
