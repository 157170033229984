<template>
  <div class="lm">
    <div class="lm__header">
      <LogoIcon />
      <span>Вход на сайт Главы города Махачкалы</span>
      <button type="button" @click="$emit('close')" class="lm__close" v-if="!loading">
        <CloseIcon />
      </button>
    </div>
    <div class="lm__body">
      <form @submit.prevent="submitForm" class="lm__form" autocomplete="off">
        <div :class="{ error: error }" class="lm__form-item">
          <label for="email">Логин</label>
          <input
            v-model="form.email"
            id="email"
            type="email"
            placeholder="Введите E-mail"
            autofocus
            autocomplete="off"
          />
        </div>
        <div :class="{ error: error }" class="lm__form-item form__item-password">
          <label for="password">Пароль</label>
          <input
            v-model="form.password"
            id="password"
            :type="showPassword ? 'text' : 'password'"
            placeholder="Введите пароль"
          />
          <a @click.prevent="showPassword = !showPassword" class="password__show-btn" href="#">
            <EyeIcon v-if="showPassword" />
            <EyeCloseIcon v-else />
          </a>
        </div>
        <span v-if="error" class="lm-input__error" role="alert">Введен некорректный логин или пароль</span>
        <div class="lm__password-restore">
          <a class="link__blue" href="#" @click.prevent="showRestoreModal">Восстановить пароль</a>
        </div>
        <button class="btn-blue btn--small" type="submit" :disabled="loading">
          <LoadingIndicator v-if="loading" />
          <template v-else>Войти</template>
        </button>
        <div class="lm__no-account">
          <span>Нет аккаунта?</span>
          <a class="lm__register-link link__blue" href="#" @click.prevent="showRegisterModal">
            Зарегистрироваться
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LogoIcon from 'components/svg/Logo.vue'
import EyeIcon from 'components/svg/EyeIcon.vue'
import EyeCloseIcon from 'components/svg/EyeCloseIcon.vue'
import CloseIcon from 'components/svg/CloseIcon.vue'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import RegisterModal from './RegisterModal.vue'
import RestoreModal from './RestorePasswordModal.vue'

export default {
  name: 'LoginModal',
  data () {
    return {
      showPassword: false,
      loading: false,
      error: false,
      form: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    showRestoreModal () {
      this.$store.state._modals = [
        {
          component: RestoreModal
        }
      ]
    },
    showRegisterModal () {
      this.$store.state._modals = [
        {
          component: RegisterModal
        }
      ]
    },
    async submitForm () {
      if (!this.loading) {
        this.loading = true
        this.error = false
        const fd = new FormData()
        Object.keys(this.form).forEach((key) => {
          if (this.form[key] !== null) fd.append(key, this.form[key])
        })
        await this.$store
          .dispatch('POST_LOGIN', fd)
          .then(({ data }) => {
            this.$store.state.user = data.user
            localStorage.setItem('apollo-token', data.user.api_token)
            location.reload()
            // this.$emit("close");
            // this.loading = false;
          })
          .catch(() => {
            this.loading = false
            this.error = true
          })
      }
    }
  },
  components: {
    LoadingIndicator,
    CloseIcon,
    LogoIcon,
    EyeIcon,
    EyeCloseIcon
  }
}
</script>

<style lang="stylus">
@import "~@/styles/parts/loginModal.styl"
</style>
