import * as def from './default.js'
import * as global from './global.js'
import * as home from './home.js'
import * as services from './services.js'
import * as feedback from './feedback.js'
import * as news from './news.js'
import * as documents from './documents.js'
import * as ministers from './ministers.js'

export default Object.assign(def, global, home, news, services, feedback, documents, ministers)
