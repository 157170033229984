<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <div class="header__top">
          <a @click.prevent="" class="header__menu-btn" href="#">
            <MenuIcon />
          </a>
          <a href="https://mkala.ru" class="header__logo" title="Главная страница">
            <Logo />
            <span>mkala.ru</span>
          </a>
          <h1 class="header__title" v-if="$route.name === 'home'">Официальный сайт Главы города Махачкалы</h1>
          <div class="header__title" v-else>Официальный сайт Главы города Махачкалы</div>
          <div class="header__user header__user--auth" @click.prevent v-if="user">
            <BellIcon />
            <span class="header__user-text">{{ getUser(user) }}</span>
            <div class="header__user-profile">
              <AccountIcon />
            </div>
            <div class="header__drop">
              <router-link :to="{ name: 'profile' }">
                <AccountIcon />
                <span>Личный кабинет</span>
              </router-link>
              <a href="#" @click.prevent="logout">
                <LogoutIcon />
                <span>Выйти</span>
              </a>
            </div>
          </div>
          <a class="header__user" @click.prevent="showLoginModal" v-else>
            <span class="header__user-text">Войти</span>
            <UserIcon />
          </a>
        </div>
        <div class="header__bottom">
          <div class="header__department">
            <div v-if="department.icon" v-html="department.icon" class="header__department-icon">
            </div>
            <div v-if="department.title" class="header__department-title">
              {{ department.title }}
            </div>
          </div>
          <div class="header__nav">
            <div class="header__nav-links">
              <router-link
                v-for="(item, index) in navLinks" :key="index"
                :to="{ name: item.route }"
                :class="{ active: isRouteActive(item.route) }"
                class="header__nav-link"
              >
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from 'components/svg/Logo.vue'
import UserIcon from 'components/svg/UserIcon.vue'
import LoginModal from 'components/modals/LoginModal.vue'
import BellIcon from 'components/svg/BellIcon.vue'
import AccountIcon from 'components/svg/AccountIcon.vue'
import LogoutIcon from 'components/svg/LogoutIcon.vue'
import MenuIcon from 'components/svg/MenuIcon.vue'

export default {
  name: 'HeaderComponent',
  data () {
    return {
      navLinks: [
        {
          title: 'Главная',
          route: 'home'
        },
        {
          title: 'Новости',
          route: 'news'
        },
        {
          title: 'Деятельность',
          route: 'activity'
        },
        {
          title: 'Структура',
          route: 'structure'
        },
        {
          title: 'Документы',
          route: 'documents'
        },
        {
          title: 'Услуги',
          route: 'services'
        },
        {
          title: 'Подведомственные учреждения',
          route: 'subdivisions'
        },
        {
          title: 'Направить официальное обращение',
          route: 'appeal'
        },
        {
          title: 'Контакты',
          route: 'contacts'
        }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    department () {
      return this.$store.state.this_department
    }
  },
  beforeMount () {
    const token = localStorage.getItem('apollo-token')
    if (token) {
      this.$store.dispatch('GET_USER', token)
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('apollo-token')
      location.reload()
    },
    getUser (user) {
      const name = user.name ? user.name.substr(0, 1) + '.' : ''
      const patronymic = user.patronymic ? user.patronymic.substr(0, 1) + '.' : ''
      return `${user.surname} ${name} ${patronymic}`
    },
    showLoginModal () {
      if (!this.user) {
        this.$store.state._modals = [
          {
            component: LoginModal
          }
        ]
      }
    },
    isRouteActive (route) {
      return !!(
        this.$route.name === route ||
        (this.$route.meta &&
          this.$route.meta.parent &&
          this.$route.meta.parent.filter((p) => p.name === route).length)
      )
    }
  },
  components: {
    LogoutIcon,
    AccountIcon,
    BellIcon,
    Logo,
    MenuIcon,
    UserIcon
  }
}
</script>

<style lang="stylus">

.header
  //position sticky
  //top 0
  //z-index 5

  &__top
    display grid
    grid-template-columns auto 1fr auto
    align-items center
    color var(--color_white)
    background-color var(--color_blue_dark)
    grid-gap 24px
    position relative
    z-index 999
    +below(1180px) {
      grid-template-columns: auto auto auto;
      justify-content space-between
    }
    +below(420px) {
      position fixed
      top 0
      left 0
      right 0
    }

    & > *
      padding 16px 0
      +below(380px) {
        padding 12px 0
      }

      &:last-child {
        padding-right 32px
        +below(1200px) {
          padding-right: 24px;
        }
        +below(380px) {
          padding-right: 16px;
        }
      }

  &__bottom {
    background-color var(--color_white)
    +above(1101px) {
      display none
    }
    +below(420px) {
      margin-top 56px // высота хедера
    }
    +below(380px) {
      margin-top 48px
    }
  }

  &__department {
    display flex
    align-items center
    gap 8px
    padding 20px 24px
    border-bottom 1px solid var(--color_gray_divider)
    +below(380px) {
      padding 12px 16px
      gap 16px
    }

    &-title {
      font-weight: 500;
      font-size 1.125em
      line-height 24px
      +below(768px) {
        font-size 1em
        line-height: 20px;
      }
      +below(340px) {
        font-size 0.875em
        line-height: 18px;
      }
    }

    &-icon {
      display flex
      flex 0 0 48px
      +below(768px) {
        flex-basis 32px
      }

      svg {
        width: 48px
        height: 48px
        +below(768px) {
          width: 32px
          height: 32px
        }
      }
    }
  }

  &__nav {
    overflow-x auto

    &::-webkit-scrollbar {
      display: none;
    }

    &-links {
      width 100%
      display flex
      gap 16px
    }

    &-link {
      display flex
      flex-shrink 0
      font-weight: 500;
      font-size: 0.875em
      line-height: 20px;
      padding 12px 0

      &:first-child {
        padding-left: 24px;
        +below(380px) {
          padding-left: 16px;
        }
      }

      &:last-child {
        padding-right: 24px;
        +below(380px) {
          padding-right: 16px;
        }
      }

      &.active {
        color var(--color_red_dark)
      }
    }
  }

  &__title
    font-weight: 500;
    font-size: 1.125em
    line-height: 24px
    width 100%
    margin 0
    +below(1180px) {
      display none
    }

  &__menu-btn {
    display flex
    align-items center
    padding-left 24px
    +above(1181px) {
      display none
    }
    +below(380px) {
      padding-left 16px
    }
  }

  &__logo
    font-weight: 500;
    font-size: 1.125em
    line-height: 24px
    display flex
    align-items center
    color var(--color_white)
    gap 8px
    padding-left 32px
    +below(1200px) {
      padding-left: 24px
    }
    +below(768px) {
      padding-left: 0
    }
    +below(380px) {
      font-size: 1em
      line-height: 20px
    }

    .icon {
      +below(380px) {
        display none
      }
    }

    &:hover
      color var(--color_white)

      svg path
        fill var(--color_white)

  &__drop
    visibility hidden
    opacity 0
    background: var(--color_white);
    box-shadow: 1px 2px 5px var(--color_black_o25);
    border-radius: var(--radius);
    padding 8px 24px
    transform: translateY(90%);
    absolute right bottom
    min-width 295px
    transition .2s

    a
      display flex
      align-items center
      justify-content flex-start
      gap 8px
      font-weight: normal;
      font-size: 1em;
      line-height: 24px;
      color: var(--color_dark);
      padding 16px 0

      &:not(:last-child)
        border-bottom: 1px solid var(--color_gray_divider);

  &__user
    display flex
    align-items center
    font-weight 400
    font-size: 1em
    line-height: 24px
    color var(--color_white)
    gap 12px
    cursor pointer

    .icon
      width: 24px;
      height: 24px;

    &:hover .header__drop
      visibility visible
      opacity 1
      transform: translateY(100%);
      z-index: 10;

    &--auth {
      gap 32px

      > .icon {
        +below(768px) {
          display none
        }
        &:after {
          content ""
          width 1px
          height 36px
          background var(--color_gray)
          right: -16px;
          position: relative;
        }
      }

      .header__user-text {
        +below(768px) {
          display none
        }
      }
    }

    &-profile {
      +above(769px) {
        display none
      }
      svg path {
        fill  var(--color_white)
      }
    }

    &-text {
      +below(420px) {
        display none
      }
    }
</style>
