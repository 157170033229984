import axios from 'axios'

/**
 * Запрос на отправку формы официального обращения
 * @param state
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
async function POST_OFFICIAL_APPEAL ({ state }, data) {
  return await axios.post(state.main_api + '/api/postofficialappeals', data.data, {
    headers: {
      Authorization: 'Bearer ' + data.token
    }
  })
}


export { POST_OFFICIAL_APPEAL }
