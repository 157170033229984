import MINISTER_OPEN_PAGE from '@/graphql/structure/OneMinister.graphql'

/**
 * Страница министра
 * @param commit
 * @param apollo
 * @param variables
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_MINISTER_PAGE ({ commit }, { apollo, variables }) {
  const { data } = await apollo.clients.dept.query({
    query: MINISTER_OPEN_PAGE,
    variables: variables
  })
  if (data.ActivePersonal) {
    commit('minister', data.ActivePersonal)
  }
  if (data.this_department) {
    commit('this_department', data.this_department)
  }
  if (data.service_banners_paginate) {
    commit('banners', data.service_banners_paginate)
  }
}

export { GET_MINISTER_PAGE }
