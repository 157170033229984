export default {
  home_page (state, data) {
    state.home_page = data
  },
  this_department (state, data) {
    state.this_department = data
  },
  banners (state, data) {
    state.banners = data
  },
  news (state, data) {
    state.news = data
  },
  news_categories (state, data) {
    state.news_categories = data
  },
  news_open (state, data) {
    state.news_open = data
  },
  services_page (state, data) {
    state.services_page = data
    state.banners = data.banners
  },
  service (state, data) {
    state.service = data
  },
  feedback_page (state, data) {
    state.feedback_page = data
  },
  documents (state, data) {
    state.documents = data
  },
  document_types (state, data) {
    state.document_types = data
  },
  subdivisions (state, data) {
    state.subdivisions = data
  },
  category_services (state, data) {
    state.category_services = data
  },
  popular_services (state, data) {
    state.popular_services = data
  },
  minister (state, data) {
    state.minister = data
  }
}
