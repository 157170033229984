import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import * as filters from './filters.js'

import App from './App.vue'
import createStore from './store'
import createRouter from './router.js'
import createApollo from './apollo.js'

import Meta from 'vue-meta'
import Cookie from 'vue-cookie'
import Seo from './plugins/seo.js'
import Notifications from 'vue-notification/dist/ssr.js'
import VueProgressBar from 'vue-progressbar'
import { VueMaskDirective } from 'v-mask'

Vue.use(VueProgressBar, {
  color: '#0377C4',
  failedColor: '#DA2127',
  thickness: '4px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})
Vue.use(Meta)
Vue.use(Cookie)
Vue.use(Seo)
Vue.use(Notifications)
Vue.directive('mask', VueMaskDirective)

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

export default function createApp (context) {
  const store = createStore(context.host)
  const router = createRouter()
  const apolloProvider = createApollo(context.host)
  sync(store, router)

  const app = new Vue({
    router,
    store,
    apolloProvider,
    render: h => h(App)
  })

  return { app, router, store, apollo: apolloProvider }
}
