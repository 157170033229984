import Vue from 'vue'
import moment from 'moment'
import { VueMaskFilter } from 'v-mask'

Vue.filter('formatDate', (date) => {
  return moment(date).format('DD.MM.YYYY')
})
Vue.filter('humanDate', (date) => {
  return moment(date).format('DD MMMM YYYY')
})
Vue.filter('robotDate', (date) => {
  return moment(date)
})
Vue.filter('humanDateRu', (date) => {
  moment.locale('ru')
  if (moment(date).format('LL') === moment(Date.now()).format('LL')) {
    return moment(date).format('LT')
  } else {
    return moment(date).format('DD MMMM YYYY')
  }
})
Vue.filter('humanDateHoursRu', (date) => {
  moment.locale('ru')
  return moment(date).format('DD MMMM YYYY\u00A0 HH:mm')
})

Vue.filter('humanDateSliderRu', (date) => {
  moment.locale('ru')
  return moment(date).format('DD MMMM YYYY, LT')
})

Vue.filter('humanDatePosterRu', (date, dateEnd) => {
  moment.locale('ru')
  if (dateEnd != null) {
    return moment(date).format('DD MMMM YYYY') + ' - ' + moment(dateEnd).format('DD MMMM YYYY')
  } else {
    return moment(date).format('DD MMMM YYYY')
  }
})

Vue.filter('image', (entity, api) => {
  if (entity && entity.img && entity.img.host && entity.img.url) {
    return 'https://' + entity.img.host + entity.img.url
  }
  if (entity && entity.img && entity.img.url) {
    return entity.img.url
  }
  return '/static/images/no-image.png'
})

Vue.filter('image_by_url', (entity) => {
  const img = entity?.img
  if (img && img.url) {
    if (img.host && img.url.indexOf('/files') === -1) {
      return 'https://' + img.host + img.url
    }
    return img.url
  }
  return '/static/images/no-image.png'
})

Vue.filter('image_alt', (entity) => {
  if (entity && entity.img && entity.img.alt) {
    return entity.img.alt
  }
  return ''
})

Vue.filter('vMask', VueMaskFilter)
