import HOME_PAGE from '@/graphql/home/HomePage.graphql'

/**
 * Главная страница
 * @param state
 * @param commit
 * @param apollo
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_HOME_PAGE ({ commit }, apollo) {
  const { data } = await apollo.clients.dept.query({
    query: HOME_PAGE
  })
  if (data.banners_paginate) {
    commit('banners', data.banners_paginate)
  }
  if (data.this_department) {
    commit('this_department', data.this_department)
  }
  commit('home_page', data)
}

export { GET_HOME_PAGE }
