import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default function createRouter () {
  const routes = [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/home/index.vue')
    },
    {
      path: '/departments/:id',
      name: 'department',
      component: () => import('./views/department/index.vue')
    },
    {
      path: '/ministers/:id',
      name: 'minister',
      component: () => import('./views/minister/index.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./views/news/list.vue')
    },
    {
      path: '/news/:id/:url',
      name: 'news_item',
      component: () => import('./views/news/open.vue'),
      meta: {
        parent: [
          {
            title: 'Новости',
            name: 'news'
          }
        ]
      }
    },
    {
      path: '/activity',
      name: 'activity',
      component: () => import('./views/activity/list.vue')
    },
    {
      path: '/structure',
      name: 'structure',
      component: () => import('./views/structure/index.vue')
    },
    {
      path: '/documents',
      name: 'documents',
      component: () => import('./views/documents/list.vue')
    },
    {
      path: '/services',
      name: 'services',
      component: () => import('./views/services/list.vue')
    },
    {
      path: '/services/:id',
      name: 'service',
      component: () => import('./views/services/open.vue'),
      meta: {
        parent: [
          {
            title: 'Услуги',
            name: 'services'
          }
        ]
      }
    },
    {
      path: '/subdivisions',
      name: 'subdivisions',
      component: () => import('./views/subdivisions/index.vue')
    },
    {
      path: '/appeal',
      name: 'appeal',
      component: () => import('./views/feedback/appeal.vue')
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: () => import('./views/feedback/contacts.vue')
    },
    {
      path: '*',
      name: 'notfound',
      component: () => import('./views/PageNotFound.vue')
    }
  ]

  return new Router({
    mode: 'history',
    fallback: true,
    routes
  })
}
