<template>
  <div class="lm">
    <div class="lm__header">
      <LogoIcon />
      <span>Восстановление пароля</span>
      <button type="button" @click="$emit('close')" class="lm__close" v-if="!loading">
        <CloseIcon />
      </button>
    </div>
    <div class="lm__body">
      <form @submit.prevent="submitForm" class="lm__form">
        <div class="lm__form-item" :class="{ error: !!form.email.message }">
          <label for="email">Логин</label>
          <div class="lm__form-item-input">
            <input
              v-model="form.email.value"
              id="email"
              type="email"
              placeholder="Введите E-mail"
              autofocus
            />
            <span class="lm__form-item-message" v-if="form.email.message">
              {{ form.email.message }}
            </span>
          </div>
        </div>
        <button class="btn-blue btn--small" type="submit" :disabled="loading">
          <LoadingIndicator v-if="loading" />
          <template v-else>Восстановить</template>
        </button>
        <div class="lm__no-account">
          <span>Нет аккаунта?</span>
          <a class="lm__register-link link__blue" href="#" @click.prevent="showRegisterModal">
            Зарегистрироваться
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import LogoIcon from 'components/svg/Logo.vue'
import CloseIcon from 'components/svg/CloseIcon.vue'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import RegisterModal from './RegisterModal.vue'
import AlertModal from './AlertModal.vue'

export default {
  name: 'RestorePasswordModal',
  data () {
    return {
      loading: false,
      form: {
        email: {
          value: null,
          message: null
        }
      }
    }
  },
  methods: {
    showRegisterModal () {
      this.$store.state._modals = [
        {
          component: RegisterModal
        }
      ]
    },
    clearMessages () {
      Object.keys(this.form).forEach((field) => {
        this.form[field].message = null
      })
    },
    async submitForm () {
      if (!this.loading) {
        this.loading = true
        this.clearMessages()
        const fd = new FormData()
        Object.keys(this.form).forEach((key) => {
          if (this.form[key].value !== null) fd.append(key, this.form[key].value)
        })
        await this.$store
          .dispatch('POST_RESTORE_PASSWORD', fd)
          .then(({ data }) => {
            this.$store.state._modals = [
              {
                component: AlertModal,
                options: {
                  message: data.message
                }
              }
            ]
            this.loading = false
          })
          .catch(({ response }) => {
            Object.keys(response.data.errors).forEach((message) => {
              Object.keys(this.form).forEach((field) => {
                if (field === message) {
                  this.form[field].message = response.data.errors[message][0]
                }
              })
            })
            this.loading = false
          })
      }
    }
  },
  components: {
    LoadingIndicator,
    CloseIcon,
    LogoIcon
  }
}
</script>

<style lang="stylus">
@import "~@/styles/parts/loginModal.styl"
</style>
